// // "use client"

// import clsx from "clsx"
// import Image from "next/image"
// import Link from "next/link"

// // import dynamic from "next/dynamic"

// // const Home = dynamic(
// //   () => import("@/modules/Home/homePage").then((module) => module.default),
// //   {
// //     ssr: false,
// //   }
// // )
// // export default Home

// export default function page() {
//   return (
//     <div className="w-full h-full text-center items-center justify-center flex flex-col">
//       <h1
//         className={clsx(
//           "relative  font-bold text-5xl cursor-pointer font-bold	",
//           "text-primary-05"
//         )}
//       >
//         <Link href="/">Luxi</Link>
//       </h1>
//       <Image
//         alt="WE ARE UNDER CONSTRUCTION"
//         width={500}
//         height={500}
//         src="/images/work.png"
//       />
//       <h2 className="font-bold">WE ARE UNDER CONSTRUCTION</h2>
//     </div>
//   )
// }

"use client"

import dynamic from "next/dynamic"

const Home = dynamic(
  () => import("@/modules/Home/homePage").then((module) => module.default),
  {
    ssr: false,
  }
)
export default Home
